var React = require("react");
var ReactDOM = require("react-dom");
var $ = require("jquery");
var common = require("../common");

function dedupe(rows, id_key) {
  const seen = new Set();
  const result = [];
  for (let row of rows) {
    const val = row[id_key];
    if (seen.has(val)) {
      // Do nothing
    } else {
      result.push(row);
      seen.add(val);
    }
  }
  return result;
}

function Filter({label, opts, id_key, label_key, nullable, selected, onChange}) {
  const deduped_opts = dedupe(opts, id_key);
  const selected_idx = (selected === null) ? -1 : deduped_opts.findIndex(r => r[id_key] === selected);

  return (
    <div
      className="form-group"
      style={{
        display: "inline-block",
        marginLeft: 15,
        position: "relative",
        marginBottom: 5,
        maxWidth: 200,
      }}
    >
      <label>{label}</label>
      <select className="form-control" value={selected_idx} onChange={ev => {
        const index = parseInt(ev.target.value);
        const value = (index === -1) ? null : deduped_opts[index][id_key];
        onChange(value);
      }}>
        {nullable ? <option value="-1">All</option> : null}
        {deduped_opts.map((opt, idx) => <option key={opt[id_key]} value={idx} >{opt[label_key]}</option>)}
      </select>
    </div>
  );
}

function MarksTable({courses, marks, show_terms}) {
  // console.log(courses);
  // console.log(marks);

  const yearToCoursesMap = React.useMemo(() => {
    const result = {};

    for (const mark of marks.rows) {

      if (!result[mark.year]) {
        result[mark.year] = new Set();
      }

      result[mark.year].add(mark.cou_id);
    }

    return result;
  }, [courses, marks]);

  // console.log(yearToCoursesMap);


  const [selectedYear, setSelectedYear] = React.useState(null);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedTerm, setSelectedTerm] = React.useState(null);

  console.log(selectedYear, selectedCourse, selectedTerm);
  
  const selectableCourses = selectedYear ? courses.filter(course => yearToCoursesMap[selectedYear].has(course.cou_id)) : courses;
  const effectiveSelectedCourse = (selectableCourses.some(s => s.cou_id === selectedCourse)) ? selectedCourse : selectableCourses[0].cou_id;
  const selectableTerms = marks.rows.filter(mark => mark.cou_id === effectiveSelectedCourse);

  // const effectiveSelectedTerm = 
  // const effectiveSelectedTerms

  const tableRows = marks.rows.filter(row => {
    if (selectedYear !== null && selectedYear !== row.year) return false;
    if (effectiveSelectedCourse !== row.cou_id) return false;
    if (show_terms && selectedTerm !== null && row.term_id !== selectedTerm) return false;
    return true;
  })
  console.log(tableRows);

  const tableRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!tableRef.current) return;

    $(tableRef.current).find("tbody tr").remove();

    if (tableRows.length < 1) {
      $(".msg-no-results").removeClass("hide");
      return;
    } else {
      $(".msg-no-results").addClass("hide");
    }

    common.build_table(tableRef.current, marks.legend, tableRows);

  }, [tableRef.current, tableRows])

  return (
    <div>
    <div className="row">
      <Filter
        label="Year"
        opts={marks.rows}
        id_key="year"
        label_key="year"
        nullable={true}
        selected={selectedYear}
        onChange={newVal => {
          setSelectedYear(newVal ? ("" + newVal) : null);
          setSelectedTerm(null);
        }}
      />
      <Filter
        label="Course"
        opts={selectableCourses}
        id_key="cou_id"
        label_key="name"
        nullable={false}
        selected={effectiveSelectedCourse}
        onChange={newVal => {
          setSelectedCourse(newVal);
          setSelectedTerm(null);
        }}
      />
      {
        show_terms ? <Filter
          label="Term"
          opts={selectableTerms}
          id_key="term_id"
          label_key="term"
          nullable={true}
          selected={selectedTerm}
          onChange={setSelectedTerm}
        /> : null
      }

    </div>

      <table ref={tableRef} className="table table-striped table-hover jdp-table">
        <thead><tr></tr></thead>
        <tbody></tbody>
      </table>
    </div>
  );
}

var _class = class {
  constructor(jdp) {
    this.jdp = jdp;
  }

  get_basic_info() {
    return {
      title_sid: "101:title_marks",
      record_type_plural: nss("106:record_type_plural_marks")
    };
  }

  async on_load() {
    window.jdp = this.jdp;
    $(".page-container").css("min-height", "calc(100vh - 60px)");
    $(".msg-no-results").addClass("hide");

    let courses = await this.jdp.api_user.fetch_data_promise("/student/marks/get_courses", null);
    if (courses.length < 1) {
      $(".msg-no-results").removeClass("hide");
      return;
    }
    let marks = await this.jdp.api_user.fetch_data_promise("/student/marks/get_all_marks", null);
    const show_terms = marks.legend.some(field => field.field == "term");

    ReactDOM.render(
      <MarksTable courses={courses} marks={marks} show_terms={show_terms} />,
      this.jdp.main_elem[0]
     );
  }
};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".jsx"), _class);